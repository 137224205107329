import { useState, useEffect } from 'react';

export interface IUseTimer {
  time: number;
  tick: number;
  speed: number;
  isRunning: boolean;
  isPaused: boolean;
  reset: () => void;
  start: () => void;
  pause: () => void;
  stop: () => void;
  faster: () => void;
  slower: () => void;
}

const useTimer = (tickMs = 1000): IUseTimer => {
  const [tick, setTick] = useState(tickMs);
  const [speed, setSpeed] = useState(1);
  const [time, setTime] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [incTimeout, setIncTimeout] = useState<NodeJS.Timer>();

  const reset = () => {
    setIsRunning(false);
    resetInterval();
    setTime(0);
  };

  const resetInterval = () => {
    if (incTimeout) {
      clearTimeout(incTimeout);
    }
    setIncTimeout(undefined);
  };

  useEffect(() => {
    if (!isRunning) return;
    resetInterval();
    const interval = setTimeout(incTimer, tick);
    setIncTimeout(interval);
  }, [time]);

  const incTimer = () => setTime(time + 1);

  const start = () => {
    if (isProcessing) return;
    setIsProcessing(true);

    resetInterval();
    const interval = setTimeout(incTimer, tick);
    setIncTimeout(interval);
    setIsRunning(true);
    setIsPaused(false);

    setIsProcessing(false);
  };

  const pause = () => {
    if (isProcessing) return;
    setIsProcessing(true);

    setIsRunning(false);
    setIsPaused(true);
    resetInterval();

    setIsProcessing(false);
  };

  const stop = () => {
    if (isProcessing) return;
    setIsProcessing(true);

    setIsRunning(false);
    setIsPaused(false);
    resetInterval();
    setTime(0);

    setIsProcessing(false);
  };

  const faster = () => {
    const newTick = tick - 100;
    if (newTick > 100) {
      setTick(newTick);
      setSpeed(speed + 1);
    }
  };

  const slower = () => {
    const newTick = tick + 100;
    if (newTick <= 1000) {
      setTick(newTick);
      setSpeed(speed - 1);
    }
  };

  return {
    time,
    tick,
    speed,
    isRunning,
    isPaused,
    reset,
    start,
    pause,
    stop,
    faster,
    slower,
  };
};

export default useTimer;
