import { IGoal } from '@gtools/shared';
import React, { useEffect, useState } from 'react';
import { Fetch } from 'react-request';
import { getApiBaseUri } from '../lib/fetchUtils';

/**
 * Get Goal
 */
export const useGoal = (goalId: number | string) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [goal, setGoal] = useState<IGoal>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/goals/${goalId}`
      );
      const data = await response.json();
      setGoal(data[0]);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!goal) fetchData();
  }, [goal]);

  return { fetching, failed, complete, goal };
};

/**
 * List Goals
 */
export const useGoals = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [goals, setGoals] = useState<IGoal[]>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/goals`
      );
      const data = await response.json();
      setGoals(data);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!goals) fetchData();
  }, [goals]);

  return { fetching, failed, complete, goals };
};

/**
 * Save Goal
 */
export interface SaveGoalProps {
  children?: any;
}

export function SaveGoal({ children }: SaveGoalProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/goals`}
      method="POST"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Edit Goal
 */
export interface UpdateGoalProps {
  goalId: number | string;
  children: any;
}

export function UpdateGoal({ goalId, children }: UpdateGoalProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/goals/${goalId}`}
      method="PATCH"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Delete Goal
 */
export interface DeleteGoalProps {
  goalId: number | string;
  children: any;
}

export function DeleteGoal({ goalId, children }: DeleteGoalProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/goals/${goalId}`}
      method="DELETE"
      credentials="same-origin"
      children={children}
    />
  );
}
