import { IDecision } from '@gtools/shared';
import React, { useEffect, useState } from 'react';
import { Fetch } from 'react-request';
import { getApiBaseUri } from '../lib/fetchUtils';

/**
 * Get Decision
 */
export const useDecision = (decisionId: number | string) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [decision, setDecision] = useState<IDecision>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/decisions/${decisionId}`
      );
      const data = await response.json();
      setDecision(data[0]);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!decision) fetchData();
  }, [decision]);

  return { fetching, failed, complete, decision };
};

/**
 * List Decisions
 */
export const useDecisions = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [decisions, setDecisions] = useState<IDecision[]>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/decisions`
      );
      const data = await response.json();
      setDecisions(data);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!decisions) fetchData();
  }, [decisions]);

  return { fetching, failed, complete, decisions };
};

/**
 * Save Decision
 */
export interface SaveDecisionProps {
  children?: any;
}

export function SaveDecision({ children }: SaveDecisionProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/decisions`}
      method="POST"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Edit Decision
 */
export interface UpdateDecisionProps {
  decisionId: number | string;
  children: any;
}

export function UpdateDecision({ decisionId, children }: UpdateDecisionProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/decisions/${decisionId}`}
      method="PATCH"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Delete Decision
 */
export interface DeleteDecisionProps {
  decisionId: number | string;
  children: any;
}

export function DeleteDecision({ decisionId, children }: DeleteDecisionProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/decisions/${decisionId}`}
      method="DELETE"
      credentials="same-origin"
      children={children}
    />
  );
}
