/**
 * App Root
 */
import { Grommet, Main } from 'grommet';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import theme from './theme';
import Layout from './layouts';
import {
  HomePage,
  LoginPage,
  ConditionPage,
  DecisionEnginePage,
  // Action Evaluators
  ListActionEvaluatorsPage,
  EditActionEvaluatorPage,
  CreateActionEvaluatorPage,
  // Action Sets
  ListActionSetsPage,
  EditActionSetPage,
  CreateActionSetPage,
  // Actions
  ListActionsPage,
  EditActionPage,
  CreateActionPage,
  // Conditions
  ListConditionsPage,
  EditConditionPage,
  CreateConditionPage,
  // Considerations
  ListConsiderationsPage,
  EditConsiderationPage,
  CreateConsiderationPage,
  // Decisions
  ListDecisionsPage,
  EditDecisionPage,
  CreateDecisionPage,
  // Goal Evaluators
  ListGoalEvaluatorsPage,
  EditGoalEvaluatorPage,
  CreateGoalEvaluatorPage,
  // Goal Sets
  ListGoalSetsPage,
  EditGoalSetPage,
  CreateGoalSetPage,
  // Goals
  ListGoalsPage,
  EditGoalPage,
  CreateGoalPage,
  // Needs
  CreateNeedPage,
  EditNeedPage,
  ListNeedsPage,
} from './pages';

const App = () => {
  return (
    <Grommet full theme={theme}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/login" element={<LoginPage />} />

            <Route
              path="/action-evaluators"
              element={<ListActionEvaluatorsPage />}
            />
            <Route
              path="/action-evaluators/create"
              element={<CreateActionEvaluatorPage />}
            />
            <Route
              path="/action-evaluators/:actionEvaluatorId"
              element={<EditActionEvaluatorPage />}
            />

            <Route path="/action-sets" element={<ListActionSetsPage />} />
            <Route
              path="/action-sets/create"
              element={<CreateActionSetPage />}
            />
            <Route
              path="/action-sets/:actionSetId"
              element={<EditActionSetPage />}
            />

            <Route path="/actions" element={<ListActionsPage />} />
            <Route path="/actions/create" element={<CreateActionPage />} />
            <Route path="/actions/:actionSetId" element={<EditActionPage />} />

            <Route path="/condition" element={<ConditionPage />} />
            <Route path="/conditions" element={<ListConditionsPage />} />
            <Route
              path="/conditions/create"
              element={<CreateConditionPage />}
            />
            <Route
              path="/conditions/:conditionId"
              element={<EditConditionPage />}
            />

            <Route
              path="/considerations"
              element={<ListConsiderationsPage />}
            />
            <Route
              path="/considerations/create"
              element={<CreateConsiderationPage />}
            />
            <Route
              path="/considerations/:considerationId"
              element={<EditConsiderationPage />}
            />

            <Route path="/decisions" element={<ListDecisionsPage />} />
            <Route path="/decisions/create" element={<CreateDecisionPage />} />
            <Route
              path="/decisions/:decisionId"
              element={<EditDecisionPage />}
            />

            <Route
              path="/goal-evaluators"
              element={<ListGoalEvaluatorsPage />}
            />
            <Route
              path="/goal-evaluators/create"
              element={<CreateGoalEvaluatorPage />}
            />
            <Route
              path="/goal-evaluators/:actionEvaluatorId"
              element={<EditGoalEvaluatorPage />}
            />

            <Route path="/goal-sets" element={<ListGoalSetsPage />} />
            <Route path="/goal-sets/create" element={<CreateGoalSetPage />} />
            <Route
              path="/goal-sets/:actionSetId"
              element={<EditGoalSetPage />}
            />

            <Route path="/goals" element={<ListGoalsPage />} />
            <Route path="/goals/create" element={<CreateGoalPage />} />
            <Route path="/goals/:actionSetId" element={<EditGoalPage />} />

            <Route path="/needs" element={<ListNeedsPage />} />
            <Route path="/needs/create" element={<CreateNeedPage />} />
            <Route path="/needs/:needId" element={<EditNeedPage />} />
            <Route
              path="/simulations/decision-engine"
              element={<DecisionEnginePage />}
            />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </Grommet>
  );
};

export default App;
