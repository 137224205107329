import { IActionEvaluator } from '@gtools/shared';
import React, { useEffect, useState } from 'react';
import { Fetch } from 'react-request';
import { getApiBaseUri } from '../lib/fetchUtils';

/**
 * Get ActionEvaluator
 */
export const useActionEvaluator = (actionEvaluatorId: number | string) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [actionEvaluator, setActionEvaluator] = useState<IActionEvaluator>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/action-evaluators/${actionEvaluatorId}`
      );
      const data = await response.json();
      setActionEvaluator(data[0]);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!actionEvaluator) fetchData();
  }, [actionEvaluator]);

  return { fetching, failed, complete, actionEvaluator };
};

/**
 * List ActionEvaluators
 */
export const useActionEvaluators = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [actionEvaluators, setActionEvaluators] =
    useState<IActionEvaluator[]>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/action-evaluators`
      );
      const data = await response.json();
      setActionEvaluators(data);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!actionEvaluators) fetchData();
  }, [actionEvaluators]);

  return { fetching, failed, complete, actionEvaluators };
};

/**
 * Save ActionEvaluator
 */
export interface SaveActionEvaluatorProps {
  children?: any;
}

export function SaveActionEvaluator({ children }: SaveActionEvaluatorProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/action-evaluators`}
      method="POST"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Edit ActionEvaluator
 */
export interface UpdateActionEvaluatorProps {
  actionEvaluatorId: number | string;
  children: any;
}

export function UpdateActionEvaluator({
  actionEvaluatorId,
  children,
}: UpdateActionEvaluatorProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/action-evaluators/${actionEvaluatorId}`}
      method="PATCH"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Delete ActionEvaluator
 */
export interface DeleteActionEvaluatorProps {
  actionEvaluatorId: number | string;
  children: any;
}

export function DeleteActionEvaluator({
  actionEvaluatorId,
  children,
}: DeleteActionEvaluatorProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/action-evaluators/${actionEvaluatorId}`}
      method="DELETE"
      credentials="same-origin"
      children={children}
    />
  );
}
