/**
 * Development Config
 */
const config = {
  api: {
    protocol: process.env.API_PROTOCOL || 'http',
    hostname: process.env.API_HOSTNAME || 'localhost',
    port: process.env.API_PORT || '3000',
    basePath: process.env.API_BASE_PATH || 'api/v1',
  },
  auth: {
    server: {
      protocol: process.env.AUTH_SERVER_PROTOCOL || 'http',
      hostname: process.env.AUTH_SERVER_HOSTNAME || 'localhost',
      port: process.env.AUTH_SERVER_PORT || '8080',
      prefix: process.env.AUTH_SERVER_PREFIX || '/api/v1alpha',
    },
  },
  logs: {
    rootNamespace: process.env.LOGS_ROOT_NAMESPACE || 'geoffware-client',
  },
};

export default config;
