/**
 * Desktop Footer
 */
import { Box, Text } from 'grommet';
import useConfig from '../../../hooks/useConfig';
import React from 'react';

import './DesktopFooter.css';

const DesktopFooter = () => {
  const { title, version } = useConfig('app');

  return (
    <Box
      className="desktop-footer"
      background="neutral-3"
      direction="row-responsive"
      align="center"
      height="xxsmall"
      pad={{ horizontal: 'small' }}
      justify="between"
    >
      <Text size="xsmall">
        <strong>{title}</strong> | v{version}
      </Text>
      <Text size="xsmall">© 2017-2022 Geoffrey Ballard</Text>
    </Box>
  );
};

export default DesktopFooter;
