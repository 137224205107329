import { INeed } from '@gtools/shared';
import React, { useEffect, useState } from 'react';
import { Fetch } from 'react-request';
import { getApiBaseUri } from '../lib/fetchUtils';

/**
 * Get Need
 */
export const useNeed = (needId: number | string) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [need, setNeed] = useState<INeed>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/needs/${needId}`
      );
      const data = await response.json();
      setNeed(data[0]);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!need) fetchData();
  }, [need]);

  return { fetching, failed, complete, need };
};

/**
 * List Needs
 */
export const useNeeds = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [needs, setNeeds] = useState<INeed[]>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/needs`
      );
      const data = await response.json();
      setNeeds(data);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!needs) fetchData();
  }, [needs]);

  return { fetching, failed, complete, needs };
};

/**
 * Save Need
 */
export interface SaveNeedProps {
  children?: any;
}

export function SaveNeed({ children }: SaveNeedProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/needs`}
      method="POST"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Edit Need
 */
export interface UpdateNeedProps {
  needId: number | string;
  children: any;
}

export function UpdateNeed({ needId, children }: UpdateNeedProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/needs/${needId}`}
      method="PATCH"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Delete Need
 */
export interface DeleteNeedProps {
  needId: number | string;
  children: any;
}

export function DeleteNeed({ needId, children }: DeleteNeedProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/needs/${needId}`}
      method="DELETE"
      credentials="same-origin"
      children={children}
    />
  );
}
