/**
 * App Layout
 */
import React from 'react';
import DesktopLayout from './DesktopLayout';

interface LayoutProps {
  children: any;
}

const Layout = ({ children }: LayoutProps) => {
  return <DesktopLayout>{children}</DesktopLayout>;
};

export default Layout;
