/**
 * Default Config
 */
import { version } from '../../../package.json';

const config = {
  app: {
    title: process.env.APP_TITLE || 'GeoffWare Dev Toolset',
    description:
      process.env.APP_DESCRIPTION ||
      'Project management suite for GeoffWare games',
    version: process.env.APP_VERSION || version,
  },
};

export default config;
