import { IAction } from '@gtools/shared';
import React, { useEffect, useState } from 'react';
import { Fetch } from 'react-request';
import { getApiBaseUri } from '../lib/fetchUtils';

/**
 * Get Action
 */
export const useAction = (actionId: number | string) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [action, setAction] = useState<IAction>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/actions/${actionId}`
      );
      const data = await response.json();
      setAction(data[0]);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!action) fetchData();
  }, [action]);

  return { fetching, failed, complete, action };
};

/**
 * List Actions
 */
export const useActions = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [actions, setActions] = useState<IAction[]>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/actions`
      );
      const data = await response.json();
      setActions(data);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!actions) fetchData();
  }, [actions]);

  return { fetching, failed, complete, actions };
};

/**
 * Save Action
 */
export interface SaveActionProps {
  children?: any;
}

export function SaveAction({ children }: SaveActionProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/actions`}
      method="POST"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Edit Action
 */
export interface UpdateActionProps {
  actionId: number | string;
  children: any;
}

export function UpdateAction({ actionId, children }: UpdateActionProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/actions/${actionId}`}
      method="PATCH"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Delete Action
 */
export interface DeleteActionProps {
  actionId: number | string;
  children: any;
}

export function DeleteAction({ actionId, children }: DeleteActionProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/actions/${actionId}`}
      method="DELETE"
      credentials="same-origin"
      children={children}
    />
  );
}
