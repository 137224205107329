import { IConsideration } from '@gtools/shared';
import React, { useEffect, useState } from 'react';
import { Fetch } from 'react-request';
import { getApiBaseUri } from '../lib/fetchUtils';

/**
 * Get Consideration
 */
export const useConsideration = (considerationId: number | string) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [consideration, setConsideration] = useState<IConsideration>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/considerations/${considerationId}`
      );
      const data = await response.json();
      setConsideration(data[0]);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!consideration) fetchData();
  }, [consideration]);

  return { fetching, failed, complete, consideration };
};

/**
 * List Considerations
 */
export const useConsiderations = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [considerations, setConsiderations] = useState<IConsideration[]>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/considerations`
      );
      const data = await response.json();
      setConsiderations(data);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!considerations) fetchData();
  }, [considerations]);

  return { fetching, failed, complete, considerations };
};

/**
 * Save Consideration
 */
export interface SaveConsiderationProps {
  children?: any;
}

export function SaveConsideration({ children }: SaveConsiderationProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/considerations`}
      method="POST"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Edit Consideration
 */
export interface UpdateConsiderationProps {
  considerationId: number | string;
  children: any;
}

export function UpdateConsideration({
  considerationId,
  children,
}: UpdateConsiderationProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/considerations/${considerationId}`}
      method="PATCH"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Delete Consideration
 */
export interface DeleteConsiderationProps {
  considerationId: number | string;
  children: any;
}

export function DeleteConsideration({
  considerationId,
  children,
}: DeleteConsiderationProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/considerations/${considerationId}`}
      method="DELETE"
      credentials="same-origin"
      children={children}
    />
  );
}
