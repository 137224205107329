import React from 'react';
import { Box, Spinner } from 'grommet';

export interface LoadingSpinnerProps {
  color?: string;
  background?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  color,
  background,
}) => (
  <Box align="center" justify="center" fill background={background}>
    <Spinner size="large" color={color || 'neutral-4'} />
  </Box>
);

export default LoadingSpinner;
