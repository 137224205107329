/**
 * Desktop Sidebar
 */
import MenuItem from '../../MenuItem';
import {
  Box,
  Button,
  DropButton,
  Sidebar,
  Nav,
  Text,
  Menu,
  Accordion,
  AccordionPanel,
} from 'grommet';
import * as Icons from 'grommet-icons';
import { IMenuItem } from 'lib/types';
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tip } from 'grommet';

// import './DesktopSidebar.css';

const defaultMenuItems: IMenuItem[] = [
  {
    label: 'Examples',
    items: [
      { label: 'List Examples', onClick: () => {} },
      { label: 'Create Example', onClick: () => {} },
    ],
  },
];

const SidebarButton = ({ label, ...rest }) => (
  <Button plain {...rest}>
    {({ hover }) => (
      <Box
        background={hover ? 'accent-1' : undefined}
        pad={{ horizontal: 'large', vertical: 'medium' }}
      >
        <Text size="large">{label}</Text>
      </Box>
    )}
  </Button>
);

const DesktopSidebar = () => {
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState<IMenuItem[]>(defaultMenuItems);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<any>();

  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  return (
    <Box fill="vertical" background="light-3">
      <Button
        fill="horizontal"
        hoverIndicator
        onClick={() => navigate('/', { replace: true })}
      >
        <Box
          pad={{ vertical: 'medium', horizontal: 'small' }}
          background="neutral-3"
          direction="row"
          align="center"
          gap="small"
        >
          <Icons.Home />
          {
            <Text size="small" weight="bold">
              Home
            </Text>
          }
        </Box>
      </Button>
      <Accordion multiple>
        <AccordionPanel
          label={
            <Box pad="small" direction="row" align="center" gap="small">
              <Icons.Gamepad />
              {showSidebar && <Text size="small">Gameplay</Text>}
            </Box>
          }
        >
          <Button
            fill="horizontal"
            hoverIndicator
            onClick={() => navigate('/simulations/decision-engine')}
          >
            <Box pad="small" justify="center">
              <Text size="small">Decision Engine</Text>
            </Box>
          </Button>
          <Button fill="horizontal" hoverIndicator>
            <Box pad="small" justify="center">
              <Text size="small">Goal Planner</Text>
            </Box>
          </Button>
        </AccordionPanel>
        <AccordionPanel
          selected={true}
          label={
            <Box pad="small" direction="row" align="center" gap="small">
              <Icons.Folder />
              {showSidebar && <Text size="small">Data</Text>}
            </Box>
          }
        >
          <Button
            fill="horizontal"
            hoverIndicator
            onClick={() => navigate('/action-evaluators')}
          >
            <Box pad="small" justify="center">
              <Text size="small">Action Evaluators</Text>
            </Box>
          </Button>

          <Button
            fill="horizontal"
            hoverIndicator
            onClick={() => navigate('/action-sets')}
          >
            <Box pad="small" justify="center">
              <Text size="small">Action Sets</Text>
            </Box>
          </Button>

          <Button
            fill="horizontal"
            hoverIndicator
            onClick={() => navigate('/actions')}
          >
            <Box pad="small" justify="center">
              <Text size="small">Actions</Text>
            </Box>
          </Button>

          <Button
            fill="horizontal"
            hoverIndicator
            onClick={() => navigate('/conditions')}
          >
            <Box pad="small" justify="center">
              <Text size="small">Conditions</Text>
            </Box>
          </Button>
          <Button
            fill="horizontal"
            hoverIndicator
            onClick={() => navigate('/considerations')}
          >
            <Box pad="small" justify="center">
              <Text size="small">Considerations</Text>
            </Box>
          </Button>

          <Button
            fill="horizontal"
            hoverIndicator
            onClick={() => navigate('/decisions')}
          >
            <Box pad="small" justify="center">
              <Text size="small">Decisions</Text>
            </Box>
          </Button>

          <Button
            fill="horizontal"
            hoverIndicator
            onClick={() => navigate('/goal-evaluators')}
          >
            <Box pad="small" justify="center">
              <Text size="small">Goal Evaluators</Text>
            </Box>
          </Button>

          <Button
            fill="horizontal"
            hoverIndicator
            onClick={() => navigate('/goal-sets')}
          >
            <Box pad="small" justify="center">
              <Text size="small">Goal Sets</Text>
            </Box>
          </Button>

          <Button
            fill="horizontal"
            hoverIndicator
            onClick={() => navigate('/goals')}
          >
            <Box pad="small" justify="center">
              <Text size="small">Goals</Text>
            </Box>
          </Button>

          <Button
            fill="horizontal"
            hoverIndicator
            onClick={() => navigate('/needs')}
          >
            <Box pad="small" justify="center">
              <Text size="small">Needs</Text>
            </Box>
          </Button>
        </AccordionPanel>
        <AccordionPanel
          label={
            <Box pad="small" direction="row" align="center" gap="small">
              <Icons.Image />
              {showSidebar && <Text size="small">GFX</Text>}
            </Box>
          }
        >
          <Button fill="horizontal" hoverIndicator>
            <Box pad="small" justify="center">
              <Text size="small">Model Viewer</Text>
            </Box>
          </Button>
          <Button fill="horizontal" hoverIndicator>
            <Box pad="small" justify="center">
              <Text size="small">Textures</Text>
            </Box>
          </Button>
        </AccordionPanel>
        <AccordionPanel
          label={
            <Box pad="small" direction="row" align="center" gap="small">
              <Icons.Volume />
              {showSidebar && <Text size="small">Audio</Text>}
            </Box>
          }
        >
          <Button fill="horizontal" hoverIndicator>
            <Box pad="small" justify="center">
              <Text size="small">SFX</Text>
            </Box>
          </Button>
          <Button fill="horizontal" hoverIndicator>
            <Box pad="small" justify="center">
              <Text size="small">Music</Text>
            </Box>
          </Button>
        </AccordionPanel>
      </Accordion>

      {/* <Menu
        label={<Text size="small">Gameplay</Text>}
        icon={<Icons.Down />}
        dropAlign={{ top: 'bottom', left: 'left' }}
        items={[
          {
            label: 'Decision Engine',
            onClick: () =>
              navigate('/gameplay/decision-engine', { replace: true }),
          },
          {
            label: 'Goal Planning',
            onClick: () =>
              navigate('/gameplay/goal-planning', { replace: true }),
          },
        ]}
      />
      <Menu
        label={<Text size="small">Data</Text>}
        icon={<Icons.Down />}
        dropAlign={{ top: 'bottom', left: 'left' }}
        items={[
          {
            label: 'Actors',
            onClick: () => navigate('/data/actors', { replace: true }),
          },
          {
            label: 'Items',
            onClick: () => navigate('/gameplay/items', { replace: true }),
          },
        ]}
      />
      <Menu
        label={<Text size="small">GFX</Text>}
        icon={<Icons.Down />}
        dropAlign={{ top: 'bottom', left: 'left' }}
        items={[
          {
            label: 'Model Viewer',
            onClick: () => navigate('/gfx/model-viewer', { replace: true }),
          },
          {
            label: 'Texture Library',
            onClick: () => navigate('/gfx/texture-library', { replace: true }),
          },
        ]}
      />
      <Menu
        label={<Text size="small">Audio</Text>}
        icon={<Icons.Down />}
        dropAlign={{ top: 'bottom', left: 'left' }}
        items={[
          {
            label: 'SFX Editor',
            onClick: () => navigate('/audio/sfx-editor', { replace: true }),
          },
        ]}
      /> */}
    </Box>
  );
};

export default DesktopSidebar;
