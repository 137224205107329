/**
 * Login View
 */
import { Box, Button, Form, FormField, Grid, Heading } from 'grommet';
import * as React from 'react';
import { useState } from 'react';

// const { login, } = actions;

export interface ILoginFormData {
  username: string;
  password: string;
}

export interface LoginProps {
  error: Error;
  login(formData: ILoginFormData): void;
}

export interface LoginFormProps {
  formData: ILoginFormData;
}

const LoginForm = ({ formData }: LoginFormProps) => {
  return (
    <Box pad="small" direction="column">
      <Form value={formData} onSubmit={({ value }) => {}}>
        <Box>
          <FormField name="username" required={true} label="Username" />
          <FormField
            name="password"
            type="password"
            required={true}
            label="Password"
          />
        </Box>
        <Box direction="row-responsive" justify="end">
          <Button type="submit" label="Log In" primary={true} />
        </Box>
      </Form>
    </Box>
  );
};

const Login = () => {
  const [formData, setFormData] = useState<ILoginFormData>();

  return (
    <Box>
      <Box direction="row-responsive" alignSelf="stretch">
        <Box alignSelf="start">
          <Heading margin="small">Login</Heading>
        </Box>
      </Box>
      {formData && <LoginForm formData={formData} />}
    </Box>
  );
};

export default Login;
