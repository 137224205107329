module.exports = {
  "name": "@gtools/client",
  "version": "0.3.0",
  "description": "Frontend client for GeoffWare dev toolset",
  "main": "public/index.tsx",
  "private": true,
  "author": "Geoffrey Ballard <geoffreyballard@gmail.com>",
  "scripts": {
    "start": "parcel serve public/index.html",
    "build": "parcel build src/index.tsx",
    "docker:build": "./dockerize",
    "dev": "NODE_ENV=development parcel public/index.html",
    "deploy": "./kubernetes/deploy",
    "deploy:dev": "./kubernetes/deploy dev",
    "deploy:staging": "./kubernetes/deploy staging",
    "deploy:prod": "./kubernetes/deploy prod",
    "update": "yarn docker:build && yarn deploy",
    "update:dev": "yarn docker:build && yarn deploy:dev",
    "update:staging": "yarn docker:build && yarn deploy:staging",
    "update:prod": "yarn docker:build && yarn deploy:prod",
    "lint": "tslint -c tslint.json -p tsconfig.json",
    "test": "echo \"Need to write some tests!\""
  },
  "dependencies": {
    "debug": "^4.3.3",
    "grommet": "^2.20.1",
    "grommet-icons": "^4.7.0",
    "moment": "^2.29.1",
    "react": "^16.8.0 || ^17",
    "react-dom": "^17.0.2",
    "react-request": "^3.2.0",
    "react-router-dom": "^6.2.1",
    "styled-components": "^5.3.3"
  },
  "devDependencies": {
    "@types/debug": "^4.1.7",
    "@types/lodash": "^4.14.178",
    "@types/node": "^17.0.14",
    "@types/react": "^17.0.38",
    "@types/react-dom": "^17.0.11",
    "@types/react-request": "^3.1.4",
    "@types/react-router-dom": "^5.3.3",
    "concurrently": "^7.0.0",
    "parcel-bundler": "^1.12.5",
    "ts-node-dev": "^1.1.8",
    "typescript": "^4.5.5"
  }
}
;