/**
 * List Goals View
 */
import { Box, Text, Main, DataTable, Button } from 'grommet';
import * as Icons from 'grommet-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, LoadingSpinner } from '../../components';
import { useGoals } from '../../data/goals';

const ListGoalsPage = () => {
  const navigate = useNavigate();

  const { fetching, failed, goals } = useGoals();

  return (
    <Main>
      <Box>
        <Box
          direction="row"
          align="center"
          pad="small"
          background="neutral-3"
          justify="between"
        >
          <Button
            onClick={() => navigate('/')}
            icon={<Icons.Previous size="medium" />}
            hoverIndicator
          />
          <Text size="large">Goals</Text>
          <Button
            onClick={() => navigate('/goals/create')}
            icon={<Icons.AddCircle size="medium" />}
            hoverIndicator
          />
        </Box>
        <Box>
          {fetching && <LoadingSpinner />}
          {failed && <ErrorMessage error={new Error('Failed to list Goals')} />}
          {goals && (
            <DataTable
              background={['white', 'light-1']}
              pad="small"
              sortable={true}
              sort={{ direction: 'asc', property: 'id' }}
              columns={[
                {
                  property: 'id',
                  header: <Text weight="bold">#</Text>,
                  primary: true,
                },
                {
                  property: 'gameid',
                  header: <Text weight="bold">GameID</Text>,
                },
                {
                  property: 'description',
                  header: <Text weight="bold">Description</Text>,
                },
                {
                  property: 'scoringfunction',
                  header: <Text weight="bold">Scoring Function</Text>,
                },
                {
                  property: 'slope',
                  header: <Text weight="bold">Slope</Text>,
                },
                {
                  property: 'exponent',
                  header: <Text weight="bold">Exponent</Text>,
                },
                {
                  property: 'midpoint',
                  header: <Text weight="bold">Midpoint</Text>,
                },
                {
                  property: 'scoringoffset',
                  header: <Text weight="bold">Offset</Text>,
                },
              ]}
              data={goals}
              onClickRow={({ datum }) => {
                navigate(`/goals/${datum.id}`);
              }}
            />
          )}
        </Box>
      </Box>
    </Main>
  );
};

export default ListGoalsPage;
