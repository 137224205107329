/**
 * Entities/Condition View
 */
import { TNumberRange } from '@gtools/shared';
import RangeInput from '../../components/Forms/RangeInput';
import {
  Stack,
  Box,
  Text,
  RangeSelector,
  CardFooter,
  Form,
  FormField,
  TextInput,
  Grid,
  Heading,
  Card,
  CardBody,
  Button,
  CardHeader,
} from 'grommet';
import * as Icons from 'grommet-icons';
import React, { useState } from 'react';
import { TextArea } from 'grommet';

export interface IConditionFormValues {
  name: string;
  description: string;
  notes: string;
  range: TNumberRange;
  input: number;
}

const defaultValues: IConditionFormValues = {
  name: '',
  description: '',
  notes: '',
  range: [0, 100],
  input: 0,
};

const rangeLabels = [];
for (let i = 0; i <= 100; i += 10) {
  rangeLabels.push(i);
}

const ConditionPage = () => {
  const [values, setValues] = useState<IConditionFormValues>(defaultValues);
  const [input, setInput] = useState<number>(0);
  const [range, setRange] = useState<[number, number]>([0, 100]);

  return (
    <Grid
      rows={['fill']}
      columns={['auto', 'auto']}
      areas={[
        { name: 'inputForm', start: [0, 0], end: [0, 0] },
        { name: 'graph', start: [1, 0], end: [1, 0] },
      ]}
      // pad="medium"
    >
      <Card
        margin="medium"
        gridArea="inputForm"
        background="light-2"
        align="center"
      >
        <CardHeader
          pad="medium"
          background="light-3"
          fill="horizontal"
          justify="around"
        >
          Condition
        </CardHeader>
        <CardBody pad="medium" fill="horizontal">
          <Form
            value={values}
            onChange={(nextValue, { touched }) => {
              console.log('Change', nextValue, touched);
              setValues(nextValue);
            }}
            onReset={() => setValues(defaultValues)}
            onSubmit={(event) =>
              console.log('Submit', event.value, event.touched)
            }
          >
            <Box>
              <FormField label="Name" name="name" required>
                <TextInput></TextInput>
              </FormField>
              <FormField label="Description" name="description" required>
                <TextArea></TextArea>
              </FormField>
              <FormField label="Notes" name="notes" required>
                <TextInput></TextInput>
              </FormField>
            </Box>
            <Box>
              <FormField
                pad={true}
                label={`Range (${range[0]}-${range[1]})`}
                name="range"
                required
              >
                <Stack>
                  <Box direction="row" justify="between">
                    {rangeLabels.map((label) => (
                      <Box key={label} pad="small" border={false}>
                        <Text style={{ fontFamily: 'monospace' }}>{label}</Text>
                      </Box>
                    ))}
                  </Box>
                  <RangeSelector
                    direction="horizontal"
                    invert={false}
                    values={range}
                    min={0}
                    max={100}
                    size="full"
                    round="small"
                    onChange={(values: TNumberRange) => setRange(values)}
                  ></RangeSelector>
                </Stack>
              </FormField>
              <FormField label="Input" name="input">
                <TextInput
                  type="number"
                  min={range[0]}
                  max={range[1]}
                ></TextInput>
              </FormField>
            </Box>
          </Form>
        </CardBody>
        <CardFooter pad="medium" fill="horizontal" background="light-3">
          <Button label="Test" />
          <Button label="Save" />
        </CardFooter>
      </Card>
      <Card
        margin="medium"
        gridArea="graph"
        background="light-2"
        align="center"
      >
        <Heading>Graph</Heading>
      </Card>
    </Grid>
  );
};

export default ConditionPage;
