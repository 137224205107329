import { IGoalEvaluator } from '@gtools/shared';
import React, { useEffect, useState } from 'react';
import { Fetch } from 'react-request';
import { getApiBaseUri } from '../lib/fetchUtils';

/**
 * Get GoalEvaluator
 */
export const useGoalEvaluator = (goalEvaluatorId: number | string) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [goalEvaluator, setGoalEvaluator] = useState<IGoalEvaluator>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/goal-evaluators/${goalEvaluatorId}`
      );
      const data = await response.json();
      setGoalEvaluator(data[0]);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!goalEvaluator) fetchData();
  }, [goalEvaluator]);

  return { fetching, failed, complete, goalEvaluator };
};

/**
 * List GoalEvaluators
 */
export const useGoalEvaluators = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [goalEvaluators, setGoalEvaluators] = useState<IGoalEvaluator[]>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/goal-evaluators`
      );
      const data = await response.json();
      setGoalEvaluators(data);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!goalEvaluators) fetchData();
  }, [goalEvaluators]);

  return { fetching, failed, complete, goalEvaluators };
};

/**
 * Save GoalEvaluator
 */
export interface SaveGoalEvaluatorProps {
  children?: any;
}

export function SaveGoalEvaluator({ children }: SaveGoalEvaluatorProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/goal-evaluators`}
      method="POST"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Edit GoalEvaluator
 */
export interface UpdateGoalEvaluatorProps {
  goalEvaluatorId: number | string;
  children: any;
}

export function UpdateGoalEvaluator({
  goalEvaluatorId,
  children,
}: UpdateGoalEvaluatorProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/goal-evaluators/${goalEvaluatorId}`}
      method="PATCH"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Delete GoalEvaluator
 */
export interface DeleteGoalEvaluatorProps {
  goalEvaluatorId: number | string;
  children: any;
}

export function DeleteGoalEvaluator({
  goalEvaluatorId,
  children,
}: DeleteGoalEvaluatorProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/goal-evaluators/${goalEvaluatorId}`}
      method="DELETE"
      credentials="same-origin"
      children={children}
    />
  );
}
