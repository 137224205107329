import { IScoringFunctionParameters, ScoringFunction } from '.';

export const defaultValues: Record<
  ScoringFunction,
  IScoringFunctionParameters
> = {
  ExponentialCurve: { slope: 0, exponent: 2, midpoint: 0, scoringoffset: 0 },
  InverseExponentialCurve: {
    slope: 1,
    exponent: 2,
    midpoint: 0,
    scoringoffset: 0,
  },
  SineCurve: { slope: 0.5, exponent: 0, midpoint: 0, scoringoffset: 0 },
  CosineCurve: { slope: 0.5, exponent: 0, midpoint: 0, scoringoffset: 0 },
  LogisticCurve: { slope: 0, exponent: 1, midpoint: 0, scoringoffset: 0 },
  LogitCurve: {
    slope: 0,
    exponent: 0,
    midpoint: 0,
    scoringoffset: 0,
    logbase: Math.E,
  },
  Linear: { slope: 1, exponent: 0, midpoint: 0, scoringoffset: 0 },
  InverseLinear: { slope: 1, exponent: 0, midpoint: 0, scoringoffset: 0 },
  SmoothStepCurve: { slope: 0, exponent: 0, midpoint: 0, scoringoffset: 0 },
  SmootherStepCurve: { slope: 0, exponent: 0, midpoint: 0, scoringoffset: 0 },
};

export const Linear = (
  input: number,
  { slope = 1, scoringoffset = 0 }: IScoringFunctionParameters
) => {
  return input / slope - scoringoffset;
};

export const InverseLinear = (
  input: number,
  { slope = 1, scoringoffset = 0 }: IScoringFunctionParameters
) => {
  return 1 - input / slope - scoringoffset;
};

/* ExponentialCurve - https://www.desmos.com/calculator/dvsqwyfjd4 */
export const ExponentialCurve = (
  input: number,
  { exponent = 2, scoringoffset = 0 }: IScoringFunctionParameters
) => {
  return 1 - (1 - Math.pow(input, exponent)) / 1 + scoringoffset;
};

/* InverseExponentialCurve */
export const InverseExponentialCurve = (
  input: number,
  { exponent = 2, slope = 1, scoringoffset = 0 }: IScoringFunctionParameters
) => {
  return slope * (1 - Math.pow(input, exponent)) + scoringoffset;
};

/* SineCurve - https://www.desmos.com/calculator/iywenft729 */
export const SineCurve = (
  input: number,
  { slope = 0.5, scoringoffset = 0 }: IScoringFunctionParameters
) => {
  return Math.sin(input * Math.PI * slope) + scoringoffset;
};

/* CosineCurve - https://www.desmos.com/calculator/p9kanbukwd */
export const CosineCurve = (
  input: number,
  { slope = 0.5, scoringoffset = 0 }: IScoringFunctionParameters
) => {
  return 1 - Math.cos(input * Math.PI * slope) + scoringoffset;
};

/* NormalizedLogisticCurve - https://www.desmos.com/calculator/bspslggkhv */
export const LogisticCurve = (
  input: number,
  { exponent = 1, midpoint = 0 }: IScoringFunctionParameters
) => {
  return (
    1 /
    (1 +
      Math.pow(
        Math.E,
        -exponent * (4 * Math.E * (input - midpoint) - 2 * Math.E)
      ))
  );
};

/* NormalizedLogitCurve - https://www.desmos.com/calculator/fmz1hgplok */
export const LogitCurve = (
  input: number,
  { logbase = Math.E }: IScoringFunctionParameters
) => {
  return (
    (Math.log(logbase) * (input / (1 - input)) + 2 * Math.E) / (4 * Math.E)
  );
};

/* SmoothStepCurve - https://www.desmos.com/calculator/fmvfinputndlff */
export const SmoothStepCurve = (input: number) => {
  return input * input * (3 - 2 * input);
};

/* SmootherStepCurve - https://www.desmos.com/calculator/wanqbm1ygl */
export const SmootherStepCurve = (input: number) => {
  return input * input * input * (input * (6 * input - 15) + 10);
};
