/**
 * Entities/Condition View
 */
import { ICondition, ScoringFunction, scoringFunctions } from '@gtools/shared';
import {
  Box,
  Text,
  RangeInput,
  Form,
  Main,
  FormField,
  TextInput,
  TextArea,
  Select,
  Card,
  CardBody,
  Button,
  CardHeader,
} from 'grommet';
import * as Icons from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import {
  DeleteCondition,
  UpdateCondition,
  useCondition,
} from '../../data/conditions';
import { ErrorMessage } from '../../components';
import { DataChart } from 'grommet';

const defaultValues: ICondition = {
  gameid: '',
  description: '',
  notes: '',
  input: 0,
  range: [0, 100],
};

const EditConditionPage = () => {
  const navigate = useNavigate();

  const urlParams = useParams();
  const {
    fetching: fetchingCondition,
    failed: fetchingFailed,
    complete: fetchingComplete,
    condition,
  } = useCondition(urlParams.conditionId);

  const [conditionId, setConditionId] = useState<number | string>();
  const [values, setValues] = useState<ICondition>();

  useEffect(() => {
    if (fetchingComplete && !condition) {
      navigate('/conditions');
    }

    if (!condition) return;
    const { id, ...data } = condition;
    setConditionId(id);
    setValues(data);
  }, [fetchingComplete, condition]);

  return (
    <UpdateCondition conditionId={conditionId}>
      {({ fetching, failed, doFetch: doUpdate }) => {
        return (
          <Main>
            <Form<ICondition>
              value={values}
              onChange={(nextValue: ICondition) => {
                setValues(nextValue);
              }}
              onReset={() => setValues(condition || defaultValues)}
              onSubmit={async () => {
                const response = await doUpdate({
                  body: JSON.stringify(values),
                });
                navigate(`/conditions`, { replace: true });
              }}
            >
              <Box
                direction="row"
                align="center"
                pad="small"
                background="neutral-3"
                justify="between"
              >
                <Button
                  onClick={() => navigate('/conditions')}
                  icon={<Icons.Previous size="medium" />}
                  hoverIndicator
                />
                <Text size="large">Edit Condition</Text>
                <Button
                  icon={<Icons.Checkmark size="medium" />}
                  hoverIndicator
                  focusIndicator={false}
                  disabled={!conditionId}
                  type="submit"
                />
              </Box>
              <Box direction="row">
                {fetchingCondition && <LoadingSpinner />}
                {fetchingFailed && (
                  <ErrorMessage
                    error={new Error('Failed to update Condition')}
                  />
                )}
                {values && (
                  <>
                    <Box pad="medium" gap="medium" fill>
                      <Card>
                        <CardHeader
                          background="dark-2"
                          fill="horizontal"
                          pad={{ horizontal: 'small', vertical: 'xsmall' }}
                        >
                          <Text size="small" weight="bold">
                            {'Metadata'.toUpperCase()}
                          </Text>
                        </CardHeader>
                        <CardBody pad="small">
                          <FormField
                            label={'GameID'.toUpperCase()}
                            name="gameid"
                            required
                            validate={{ regexp: /^[a-z]/i }}
                          />
                          <FormField
                            label={'Description'.toUpperCase()}
                            name="description"
                            component={TextArea}
                          />
                          <FormField
                            label={'Notes'.toUpperCase()}
                            name="notes"
                            component={TextArea}
                          />
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader
                          background="dark-2"
                          fill="horizontal"
                          pad={{ horizontal: 'small', vertical: 'xsmall' }}
                        >
                          <Text size="small" weight="bold">
                            {'Condition Parameters'.toUpperCase()}
                          </Text>
                        </CardHeader>
                        <CardBody pad="small">
                          <Box direction="row" align="end">
                            <Box>
                              {/* <FormField
                                label={`Slope (${values.slope})`.toUpperCase()}
                                name="slope"
                                min={0}
                                max={100}
                                step={0.1}
                                component={TextInput}
                              /> */}
                            </Box>
                            <Box fill="horizontal">
                              {/* <FormField
                                name="slope"
                                min={0}
                                max={100}
                                step={0.1}
                                component={RangeInput}
                              /> */}
                            </Box>
                          </Box>
                        </CardBody>
                      </Card>
                    </Box>
                    <Box pad="medium" gap="medium" fill>
                      <Card>
                        <CardHeader
                          background="dark-2"
                          fill="horizontal"
                          pad={{ horizontal: 'small', vertical: 'xsmall' }}
                        >
                          <Text size="small" weight="bold">
                            {'Danger Zone'.toUpperCase()}
                          </Text>
                        </CardHeader>
                        <CardBody pad="small" align="center">
                          <DeleteCondition conditionId={conditionId}>
                            {({
                              fetching: isDeleting,
                              failed: deleteFailed,
                              doFetch: doDelete,
                            }) => {
                              if (isDeleting) return <LoadingSpinner />;
                              if (deleteFailed)
                                return (
                                  <ErrorMessage
                                    error={
                                      new Error('Failed to delete Condition!')
                                    }
                                  />
                                );
                              return (
                                <Box direction="row">
                                  <Button
                                    icon={
                                      <Icons.Trash color="red" size="medium" />
                                    }
                                    label={
                                      <Text color="red">Delete Condition</Text>
                                    }
                                    hoverIndicator
                                    focusIndicator={false}
                                    disabled={!conditionId}
                                    onClick={async () => {
                                      await doDelete();
                                      navigate('/conditions');
                                    }}
                                  />
                                </Box>
                              );
                            }}
                          </DeleteCondition>
                        </CardBody>
                      </Card>
                    </Box>
                  </>
                )}
              </Box>
            </Form>
          </Main>
        );
      }}
    </UpdateCondition>
  );
};

export default EditConditionPage;
