/**
 * Card
 */
import { Box, Text } from 'grommet';
import React from 'react';

interface CardProps {
  background?: string;
  children: any;
  gridArea?: string;
  title: string;
}

const Card = ({ background, children, gridArea, title }: CardProps) => (
  <Box
    gridArea={gridArea}
    margin="small"
    direction="column"
    border={{ color: background, size: 'medium' }}
    round="small"
  >
    <Box pad="small" background={background} color="white">
      <Text>{title}</Text>
    </Box>
    {children}
  </Box>
);

export default Card;
