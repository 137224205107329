/**
 * App Config
 */
import debug from 'debug';
import { get, merge } from 'lodash';

import * as envConfigs from './env';

const log = debug('geoffware-client:config');

const all = get(envConfigs, 'all');
const envName = process.env.NODE_ENV || 'development';

let env: any = {};

if (get(envConfigs, envName)) {
  env = get(envConfigs, envName);
  log(`Loaded config for environment '${envName}'`);
} else {
  log(
    `No config found for environment '${envName}'; app will use default settings`
  );
}

const config = merge({}, all, env);

export default config;
