/**
 * Decision Engine Page
 */
import { scoringFunctions } from '@gtools/shared';
import {
  Box,
  Text,
  Main,
  Card,
  Grid,
  DataTable,
  Meter,
  CardBody,
  Button,
  Clock,
  Tip,
  Keyboard,
  CardHeader,
} from 'grommet';
import * as Icons from 'grommet-icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConsiderations } from '../../data/considerations';
import { useNeeds } from '../../data/needs';
import { useTimer } from '../../hooks';
import { LoadingSpinner, Timer } from '../../components';

const DecisionEnginePage = () => {
  const navigate = useNavigate();

  const {
    time: timerValue,
    tick,
    speed,
    isRunning,
    isPaused,
    start,
    pause,
    stop,
    faster,
    slower,
  } = useTimer();

  const { considerations } = useConsiderations();
  const { needs } = useNeeds();

  // const [pauseTimer, setPauseTimer] = useState<boolean>(false);

  const [input, setInput] = useState<number>(0);
  const [inputStep, setInputStep] = useState<number>(0.01);

  const [simulationLog, setSimulationLog] = useState<string[]>([]);
  const [simulationType, setSimulationType] = useState<string[]>([]);
  // const [scores, setScores] = useState([]);

  useEffect(() => {
    if (timerValue) {
      setInput(input + inputStep);
    }
  }, [timerValue]);

  return (
    <Keyboard onSpace={() => (isRunning ? pause() : start())}>
      <Main>
        <Box
          direction="row"
          align="center"
          pad="small"
          background="neutral-3"
          justify="between"
        >
          <Button
            onClick={() => navigate('/')}
            icon={<Icons.Previous size="medium" />}
            hoverIndicator
          />
          <Text size="large">Simulator</Text>
          <Button
            icon={<Icons.Next size="medium" />}
            hoverIndicator
            disabled
            focusIndicator={false}
            type="submit"
          />
        </Box>
        {/* <Grid
        fill
        rows={['xsmall', 'auto', 'auto']}
        columns={['auto', 'small']}
        areas={[
          { name: 'simulation', start: [0, 0], end: [0, 0] },
          { name: 'scores', start: [0, 1], end: [0, 1] },
          { name: 'log', start: [0, 2], end: [0, 2] },
          { name: 'timer', start: [1, 0], end: [1, 0] },
          { name: 'controls', start: [1, 1], end: [1, 1] },
          { name: 'needs', start: [1, 2], end: [1, 2] },
        ]}
        pad="medium"
        gap="medium"
      >
        <Card gridArea="simulation">
          <CardHeader
            background="dark-2"
            fill="horizontal"
            pad={{ horizontal: 'small', vertical: 'xsmall' }}
          >
            <Text size="small" weight="bold">
              {'Simulation'.toUpperCase()}
            </Text>
          </CardHeader>
          <CardBody pad={{ horizontal: 'medium' }} justify="center">
            <Select
              options={['DecisionEngine', 'GoalPlanner']}
              value={simulationType}
              onChange={(e) => setSimulationType(e.target.value)}
            ></Select>
          </CardBody>
        </Card>
        
        
      </Grid> */}

        <Box direction="row">
          <Box pad="medium" gap="medium" fill>
            <Card gridArea="scores">
              <CardHeader
                background="dark-2"
                fill="horizontal"
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text size="small" weight="bold">
                  {'Scores'.toUpperCase()}
                </Text>
              </CardHeader>
              <CardBody pad="small" align="center">
                {considerations && (
                  <DataTable
                    fill
                    columns={[
                      {
                        property: 'gameid',
                        header: <Text>Consideration</Text>,
                        primary: true,
                      },
                      {
                        property: 'score',
                        header: 'Score',
                        render: ({ score }) => (
                          <Box
                            direction="row"
                            pad={{ vertical: 'xsmall' }}
                            gap="small"
                            align="center"
                          >
                            <Text>
                              {parseFloat(score) === 10
                                ? Number(1).toFixed(3)
                                : parseFloat(score).toFixed(3)}
                            </Text>
                            <Meter
                              values={[{ value: score }]}
                              min={0}
                              max={1}
                              thickness="small"
                              size="small"
                              round
                            />
                          </Box>
                        ),
                      },
                    ]}
                    data={considerations.map((consideration) => {
                      const {
                        gameid,
                        slope,
                        exponent,
                        midpoint,
                        scoringoffset,
                      } = consideration;

                      const score = scoringFunctions[
                        consideration.scoringfunction
                      ](input, { slope, exponent, midpoint, scoringoffset });

                      return {
                        gameid,
                        score,
                      };
                    })}
                  />
                )}
              </CardBody>
            </Card>
            <Card gridArea="log">
              <CardHeader
                background="dark-2"
                fill="horizontal"
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text size="small" weight="bold">
                  {'Log'.toUpperCase()}
                </Text>
              </CardHeader>
              <CardBody pad="small" align="center">
                {!simulationLog.length && (
                  <Box pad="xsmall" fill="horizontal" align="center">
                    <Text color="dark-4">Nothing has been logged yet.</Text>
                  </Box>
                )}
                {simulationLog.length > 0 &&
                  simulationLog.map((logItem, i) => (
                    <Box key={i} pad="xsmall" fill="horizontal">
                      <Text>{logItem}</Text>
                    </Box>
                  ))}
              </CardBody>
            </Card>
          </Box>
          <Box pad="medium" gap="medium">
            <Card gridArea="timer">
              <CardHeader
                background="dark-2"
                fill="horizontal"
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text size="small" weight="bold">
                  {'Timer'.toUpperCase()}{' '}
                  {isRunning ? '(RUNNING)' : isPaused ? '(PAUSED)' : ''}
                </Text>
              </CardHeader>
              <CardBody
                pad="small"
                justify="center"
                align="center"
                background={
                  isRunning ? 'status-ok' : isPaused ? 'status-warning' : ''
                }
              >
                <Timer value={timerValue} />
              </CardBody>
            </Card>
            <Card gridArea="speed">
              <CardHeader
                background="dark-2"
                fill="horizontal"
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text size="small" weight="bold">
                  {'Speed'.toUpperCase()}
                </Text>
              </CardHeader>
              <CardBody pad="small" justify="center" align="center">
                <Box direction="row">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((s) => (
                    <Button
                      plain
                      key={s}
                      icon={
                        s <= speed ? (
                          <Icons.CaretNext color="neutral-3" />
                        ) : (
                          <Icons.Next />
                        )
                      }
                      onClick={() => {}}
                    ></Button>
                  ))}
                </Box>
              </CardBody>
            </Card>
            <Card gridArea="controls">
              <CardHeader
                background="dark-2"
                fill="horizontal"
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text size="small" weight="bold">
                  {'Controls'.toUpperCase()}
                </Text>
              </CardHeader>
              <CardBody align="center">
                <Box direction="row">
                  <Tip content="Slow Down">
                    <Button icon={<Icons.Rewind />} onClick={() => slower()} />
                  </Tip>
                  <Tip content={isRunning ? 'Pause' : 'Play'}>
                    <Button
                      icon={isRunning ? <Icons.Pause /> : <Icons.Play />}
                      onClick={() => (isRunning ? pause() : start())}
                    />
                  </Tip>
                  <Tip content="Stop">
                    <Button
                      icon={<Icons.Stop />}
                      onClick={() => {
                        stop();
                        setInput(0);
                      }}
                    />
                  </Tip>
                  <Tip content="Speed Up">
                    <Button
                      icon={<Icons.FastForward />}
                      onClick={() => faster()}
                    />
                  </Tip>
                </Box>
              </CardBody>
            </Card>
            <Card gridArea="needs">
              <CardHeader
                background="dark-2"
                fill="horizontal"
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text size="small" weight="bold">
                  {'Needs'.toUpperCase()}
                </Text>
              </CardHeader>
              <CardBody pad="small" align="center">
                {!needs && <LoadingSpinner color="neutral-3" />}
                {needs && (
                  <DataTable
                    fill
                    columns={[
                      {
                        property: 'gameid',
                        header: <Text>Need</Text>,
                        primary: true,
                      },
                      {
                        property: 'value',
                        header: 'Value',
                        render: (datum: { value: string }) => (
                          <Box
                            direction="row"
                            pad={{ vertical: 'xsmall' }}
                            gap="small"
                            align="center"
                          >
                            <Text>
                              {parseFloat(datum.value) === 10
                                ? Number(1).toFixed(3)
                                : parseFloat(datum.value).toFixed(3)}
                            </Text>
                            <Meter
                              values={[{ value: datum.value }]}
                              min={0}
                              max={1}
                              thickness="small"
                              size="small"
                              round
                            />
                          </Box>
                        ),
                      },
                    ]}
                    data={needs.map((need) => {
                      const {
                        gameid,
                        slope,
                        exponent,
                        midpoint,
                        scoringoffset,
                      } = need;

                      const value = scoringFunctions[need.decayfunction](
                        input,
                        {
                          slope,
                          exponent,
                          midpoint,
                          scoringoffset,
                        }
                      );

                      return {
                        gameid,
                        value,
                      };
                    })}
                  />
                )}
              </CardBody>
            </Card>
          </Box>
        </Box>
      </Main>
    </Keyboard>
  );
};

export default DecisionEnginePage;
