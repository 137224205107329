import React from 'react';
import { Box, Text } from 'grommet';
import { Alert as AlertIcon } from 'grommet-icons';

export interface ErrorMessageProps {
  error: Error;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => (
  <Box align="center" justify="center" direction="row" fill gap="small">
    <AlertIcon size="large" />
    <Box>
      <Text size="xlarge" weight="bold">
        Error
      </Text>
      <Text>{error.message}</Text>
    </Box>
  </Box>
);

export default ErrorMessage;
