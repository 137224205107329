/**
 * Entities/Consideration View
 */
import {
  IConsideration,
  ScoringFunction,
  scoringFunctions,
} from '@gtools/shared';
import {
  Box,
  Text,
  RangeInput,
  Form,
  Main,
  FormField,
  TextInput,
  TextArea,
  Select,
  Card,
  CardBody,
  Button,
  CardHeader,
} from 'grommet';
import * as Icons from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import {
  DeleteConsideration,
  UpdateConsideration,
  useConsideration,
} from '../../data/considerations';
import { ErrorMessage } from '../../components';
import { DataChart } from 'grommet';

const defaultValues: IConsideration = {
  gameid: '',
  description: '',
  notes: '',
  scoringfunction: ScoringFunction.ExponentialCurve,
  slope: 1,
  exponent: 2,
  midpoint: 0,
  scoringoffset: 0,
  logbase: Math.E,
};

const rangeLabels = [];
for (let i = 0; i <= 100; i += 10) {
  rangeLabels.push(i);
}

const useScoringCurveData = ({
  scoringfunction,
  slope,
  exponent,
  midpoint,
  scoringoffset,
  logbase,
}) => {
  const getScore = scoringFunctions[scoringfunction];

  if (typeof getScore !== 'function') return;

  const data = [];

  const maxScore = getScore(100, {
    slope,
    exponent,
    midpoint,
    scoringoffset,
    logbase,
  });

  for (let i = 0; i <= 100; i += 1) {
    const score = getScore(i, {
      slope,
      exponent,
      midpoint,
      scoringoffset,
      logbase,
    });
    data.push({
      input: i,
      score: score / maxScore,
    });
  }

  return data;
};

const EditConsiderationPage = () => {
  const navigate = useNavigate();

  const urlParams = useParams();
  const {
    fetching: fetchingConsideration,
    failed: fetchingFailed,
    complete: fetchingComplete,
    consideration,
  } = useConsideration(urlParams.considerationId);

  const [considerationId, setConsiderationId] = useState<number | string>();
  const [values, setValues] = useState<IConsideration>();
  const [chartValues, setChartValues] = useState<any[]>([]);
  const [chartLoading, setChartLoading] = useState<boolean>(false);

  useEffect(() => {
    if (fetchingComplete && !consideration) {
      navigate('/considerations');
    }

    if (!consideration) return;
    const { id, ...data } = consideration;
    setConsiderationId(id);
    setValues(data);
  }, [fetchingComplete, consideration]);

  useEffect(() => {
    if (!values?.scoringfunction) return;

    setChartLoading(true);

    const {
      scoringfunction,
      slope,
      exponent,
      midpoint,
      scoringoffset,
      logbase,
    } = values;

    const chartValues = useScoringCurveData({
      scoringfunction,
      slope,
      exponent,
      midpoint,
      scoringoffset,
      logbase,
    });

    setChartValues(chartValues);

    setChartLoading(false);
  }, [
    values?.scoringfunction,
    values?.slope,
    values?.exponent,
    values?.midpoint,
    values?.scoringoffset,
    values?.logbase,
  ]);

  return (
    <UpdateConsideration considerationId={considerationId}>
      {({ fetching, failed, doFetch: doUpdate }) => {
        return (
          <Main>
            <Form<IConsideration>
              value={values}
              onChange={(nextValue: IConsideration) => {
                setValues(nextValue);
              }}
              onReset={() => setValues(consideration || defaultValues)}
              onSubmit={async () => {
                const response = await doUpdate({
                  body: JSON.stringify(values),
                });
                navigate(`/considerations`, { replace: true });
              }}
            >
              <Box
                direction="row"
                align="center"
                pad="small"
                background="neutral-3"
                justify="between"
              >
                <Button
                  onClick={() => navigate('/considerations')}
                  icon={<Icons.Previous size="medium" />}
                  hoverIndicator
                />
                <Text size="large">Edit Consideration</Text>
                <Button
                  icon={<Icons.Checkmark size="medium" />}
                  hoverIndicator
                  focusIndicator={false}
                  disabled={!considerationId}
                  type="submit"
                />
              </Box>
              <Box direction="row">
                {fetchingConsideration && <LoadingSpinner />}
                {fetchingFailed && (
                  <ErrorMessage
                    error={new Error('Failed to update Consideration')}
                  />
                )}
                {values && (
                  <>
                    <Box pad="medium" gap="medium" fill>
                      <Card>
                        <CardHeader
                          background="dark-2"
                          fill="horizontal"
                          pad={{ horizontal: 'small', vertical: 'xsmall' }}
                        >
                          <Text size="small" weight="bold">
                            {'Metadata'.toUpperCase()}
                          </Text>
                        </CardHeader>
                        <CardBody pad="small">
                          <FormField
                            label={'GameID'.toUpperCase()}
                            name="gameid"
                            required
                            validate={{ regexp: /^[a-z]/i }}
                          />
                          <FormField
                            label={'Description'.toUpperCase()}
                            name="description"
                            component={TextArea}
                          />
                          <FormField
                            label={'Notes'.toUpperCase()}
                            name="notes"
                            component={TextArea}
                          />
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader
                          background="dark-2"
                          fill="horizontal"
                          pad={{ horizontal: 'small', vertical: 'xsmall' }}
                        >
                          <Text size="small" weight="bold">
                            {'Scoring Inputs'.toUpperCase()}
                          </Text>
                        </CardHeader>
                        <CardBody pad="small">
                          <FormField
                            label={'Scoring Function'.toUpperCase()}
                            name="scoringfunction"
                            component={Select}
                            options={[
                              ScoringFunction.Linear,
                              ScoringFunction.InverseLinear,
                              ScoringFunction.ExponentialCurve,
                              ScoringFunction.InverseExponentialCurve,
                              ScoringFunction.SineCurve,
                              ScoringFunction.CosineCurve,
                              ScoringFunction.LogisticCurve,
                              ScoringFunction.LogitCurve,
                              ScoringFunction.SmoothStepCurve,
                              ScoringFunction.SmootherStepCurve,
                            ]}
                          />
                          <Box direction="row" align="end">
                            <Box>
                              <FormField
                                label={`Slope (${values.slope})`.toUpperCase()}
                                name="slope"
                                min={0}
                                max={100}
                                step={0.1}
                                component={TextInput}
                              />
                            </Box>
                            <Box fill="horizontal">
                              <FormField
                                name="slope"
                                min={0}
                                max={100}
                                step={0.1}
                                component={RangeInput}
                              />
                            </Box>
                          </Box>
                          <Box direction="row" align="end">
                            <Box>
                              <FormField
                                label={`Exponent (${values.exponent})`.toUpperCase()}
                                name="exponent"
                                min={0}
                                max={100}
                                step={0.1}
                                component={TextInput}
                              />
                            </Box>
                            <Box fill="horizontal">
                              <FormField
                                name="exponent"
                                min={0}
                                max={100}
                                step={0.1}
                                component={RangeInput}
                              />
                            </Box>
                          </Box>
                          <Box direction="row" align="end">
                            <Box>
                              <FormField
                                label={`Midpoint (${values.midpoint})`.toUpperCase()}
                                name="midpoint"
                                min={0}
                                max={100}
                                step={0.1}
                                component={TextInput}
                              />
                            </Box>
                            <Box fill="horizontal">
                              <FormField
                                name="midpoint"
                                min={0}
                                max={100}
                                step={0.1}
                                component={RangeInput}
                              />
                            </Box>
                          </Box>
                          <Box direction="row" align="end">
                            <Box>
                              <FormField
                                label={`Offset (${values.scoringoffset})`.toUpperCase()}
                                name="scoringoffset"
                                min={0}
                                max={100}
                                step={0.1}
                                component={TextInput}
                              />
                            </Box>
                            <Box fill="horizontal">
                              <FormField
                                name="scoringoffset"
                                min={0}
                                max={100}
                                step={0.1}
                                component={RangeInput}
                              />
                            </Box>
                          </Box>
                        </CardBody>
                      </Card>
                    </Box>
                    <Box pad="medium" gap="medium" fill>
                      <Card>
                        <CardHeader
                          background="dark-2"
                          fill="horizontal"
                          pad={{ horizontal: 'small', vertical: 'xsmall' }}
                        >
                          <Text size="small" weight="bold">
                            {'Scoring Curve'.toUpperCase()}
                          </Text>
                        </CardHeader>
                        <CardBody pad="small" align="center">
                          {chartLoading && <LoadingSpinner />}
                          {chartValues && (
                            <DataChart
                              data={chartValues}
                              series={[
                                {
                                  label: 'Input',
                                  property: 'input',
                                },
                                {
                                  label: 'Score',
                                  property: 'score',
                                },
                              ]}
                              chart={[
                                {
                                  property: 'score',
                                  thickness: 'xsmall',
                                  type: 'line',
                                  color: 'neutral-3',
                                },
                              ]}
                              guide={{
                                x: { granularity: 'medium' },
                                y: { granularity: 'fine' },
                              }}
                              size={{ width: 'medium', height: 'medium' }}
                              detail
                            ></DataChart>
                          )}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader
                          background="dark-2"
                          fill="horizontal"
                          pad={{ horizontal: 'small', vertical: 'xsmall' }}
                        >
                          <Text size="small" weight="bold">
                            {'Simulation'.toUpperCase()}
                          </Text>
                        </CardHeader>
                        <CardBody pad="small" align="center">
                          <Box direction="row">
                            <Button icon={<Icons.Play />} />
                            <Button icon={<Icons.Pause />} />
                            <Button icon={<Icons.Stop />} />
                          </Box>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader
                          background="dark-2"
                          fill="horizontal"
                          pad={{ horizontal: 'small', vertical: 'xsmall' }}
                        >
                          <Text size="small" weight="bold">
                            {'Danger Zone'.toUpperCase()}
                          </Text>
                        </CardHeader>
                        <CardBody pad="small" align="center">
                          <DeleteConsideration
                            considerationId={considerationId}
                          >
                            {({
                              fetching: isDeleting,
                              failed: deleteFailed,
                              doFetch: doDelete,
                            }) => {
                              if (isDeleting) return <LoadingSpinner />;
                              if (deleteFailed)
                                return (
                                  <ErrorMessage
                                    error={
                                      new Error(
                                        'Failed to delete Consideration!'
                                      )
                                    }
                                  />
                                );
                              return (
                                <Box direction="row">
                                  <Button
                                    icon={
                                      <Icons.Trash color="red" size="medium" />
                                    }
                                    label={
                                      <Text color="red">
                                        Delete Consideration
                                      </Text>
                                    }
                                    hoverIndicator
                                    focusIndicator={false}
                                    disabled={!considerationId}
                                    onClick={async () => {
                                      await doDelete();
                                      navigate('/considerations');
                                    }}
                                  />
                                </Box>
                              );
                            }}
                          </DeleteConsideration>
                        </CardBody>
                      </Card>
                    </Box>
                  </>
                )}
              </Box>
            </Form>
          </Main>
        );
      }}
    </UpdateConsideration>
  );
};

export default EditConsiderationPage;
