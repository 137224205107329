import { grommet, ThemeType } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

const customTheme: ThemeType = {
  global: {
    font: {
      family: 'PressStart2P, sans-serif',
    },
    colors: {
      playing: 'status-ok',
      paused: 'status-warning',
    },
  },
  accordion: {
    border: {
      size: '0',
    },
  },
  tip: {
    content: {
      background: 'white',
    },
  },
  meter: {
    color: 'neutral-3',
  },
  button: {
    border: {
      color: 'white',
    },
    color: { dark: 'dark-1' },
    primary: {
      color: 'white',
    },
  },
  formField: {
    label: {
      color: 'dark-3',
      size: 'xsmall',
      margin: { horizontal: '0' },
      weight: 600,
    },
  },
  rangeInput: {
    thumb: {
      color: 'neutral-3',
    },
  },
};

export default deepMerge(grommet, customTheme);
