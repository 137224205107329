/**
 * Entities/Condition View
 */
import {
  ICondition,
  IScoringFunctionParameters,
  ScoringFunction,
  scoringFunctions,
} from '@gtools/shared';
import {
  Box,
  Text,
  RangeInput,
  Form,
  Main,
  FormField,
  TextInput,
  TextArea,
  Select,
  Card,
  CardBody,
  Button,
  CardHeader,
} from 'grommet';
import * as Icons from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import { SaveCondition } from '../../data/conditions';
import { ErrorMessage } from '../../components';
import { DataChart } from 'grommet';

const defaultValues: ICondition = {
  gameid: '',
  description: '',
  notes: '',
  input: 0,
  range: [0, 100],
};

const CreateConditionPage = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState<ICondition>(defaultValues);

  return (
    <SaveCondition>
      {({ fetching, failed, doFetch }) => {
        if (fetching) return <LoadingSpinner />;
        if (failed)
          return <ErrorMessage error={new Error('Failed to save Condition')} />;
        return (
          <Main>
            <Form<ICondition>
              value={values}
              onChange={(nextValue: ICondition) => {
                setValues(nextValue);
              }}
              onReset={() => setValues(defaultValues)}
              onSubmit={async () => {
                const response = await doFetch({
                  body: JSON.stringify(values),
                });
                navigate('/conditions');
              }}
            >
              <Box
                direction="row"
                align="center"
                pad="small"
                background="neutral-3"
                justify="between"
              >
                <Button
                  onClick={() => navigate('/conditions')}
                  icon={<Icons.Previous size="medium" />}
                  hoverIndicator
                />
                <Text size="large">New Condition</Text>
                <Button
                  icon={<Icons.Checkmark size="medium" />}
                  hoverIndicator
                  focusIndicator={false}
                  type="submit"
                />
              </Box>
              <Box direction="row">
                <Box pad="medium" gap="medium" fill>
                  <Card>
                    <CardHeader
                      background="dark-2"
                      fill="horizontal"
                      pad={{ horizontal: 'small', vertical: 'xsmall' }}
                    >
                      <Text size="small" weight="bold">
                        {'Metadata'.toUpperCase()}
                      </Text>
                    </CardHeader>
                    <CardBody pad="small">
                      <FormField
                        label={'GameID'.toUpperCase()}
                        name="gameid"
                        required
                        validate={{ regexp: /^[a-z]/i }}
                      />
                      <FormField
                        label={'Description'.toUpperCase()}
                        name="description"
                        component={TextArea}
                      />
                      <FormField
                        label={'Notes'.toUpperCase()}
                        name="notes"
                        component={TextArea}
                      />
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader
                      background="dark-2"
                      fill="horizontal"
                      pad={{ horizontal: 'small', vertical: 'xsmall' }}
                    >
                      <Text size="small" weight="bold">
                        {'Condition Parameters'.toUpperCase()}
                      </Text>
                    </CardHeader>
                    <CardBody pad="small">
                      <Box direction="row" align="end">
                        <Box>
                          {/* <FormField
                                label={`Slope (${values.slope})`.toUpperCase()}
                                name="slope"
                                min={0}
                                max={100}
                                step={0.1}
                                component={TextInput}
                              /> */}
                        </Box>
                        <Box fill="horizontal">
                          {/* <FormField
                                name="slope"
                                min={0}
                                max={100}
                                step={0.1}
                                component={RangeInput}
                              /> */}
                        </Box>
                      </Box>
                    </CardBody>
                  </Card>
                </Box>
              </Box>
            </Form>
          </Main>
        );
      }}
    </SaveCondition>
  );
};

export default CreateConditionPage;
