/**
 * Desktop Layout
 */
import { Box, Grid, Main } from 'grommet';
import React from 'react';
import { DesktopFooter, DesktopHeader, DesktopSidebar } from '../../components';

interface DesktopLayoutProps {
  children: any;
}

const DesktopLayout = ({ children }: DesktopLayoutProps) => {
  return (
    <Grid
      fill
      rows={['auto', 'xxsmall']}
      columns={['small', 'auto']}
      // rows={['xxsmall', 'auto', 'xxsmall']}
      // columns={['small', 'auto', 'small']}
      areas={[
        { name: 'left-sidebar', start: [0, 0], end: [0, 1] },
        { name: 'main', start: [1, 0], end: [1, 0] },
        { name: 'footer', start: [0, 1], end: [1, 1] },
        // { name: 'header', start: [1, 0], end: [2, 0] },
        // { name: 'left-sidebar', start: [0, 0], end: [0, 1] },
        // { name: 'main', start: [1, 1], end: [2, 1] },
        // { name: 'footer', start: [0, 2], end: [2, 2] },
      ]}
    >
      {/* <Box gridArea="header">
        <DesktopHeader />
      </Box> */}
      <Box gridArea="left-sidebar">
        <DesktopSidebar />
      </Box>
      <Box gridArea="main">{children}</Box>
      {/* <Box gridArea="right-sidebar">
        <DesktopSidebar />
      </Box> */}
      <Box gridArea="footer">
        <DesktopFooter />
      </Box>
    </Grid>
  );
};

export default DesktopLayout;
