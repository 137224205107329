/**
 * Desktop Header
 */
import MenuItem from '../../../components/MenuItem';
import { IMenuItem } from '../../../lib/types';
import { Box, Button, DropButton } from 'grommet';
import * as Icons from 'grommet-icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import './DesktopHeader.css';
import { Menu } from 'grommet';
import { Text } from 'grommet';

const useDefaultMenuItems = () => {
  const navigate = useNavigate();
  const [defaultMenuItems, setDefaultMenuItems] = useState<IMenuItem[]>([
    {
      label: 'Decisions',
      items: [
        {
          label: 'Action Context',
          onClick: () => navigate('/action-context', { replace: true }),
        },
        {
          label: 'Action Evaluator',
          onClick: () => navigate('/action-evaluator', { replace: true }),
        },
        {
          label: 'Action Set',
          onClick: () => navigate('/action-set', { replace: true }),
        },
        {
          label: 'Consideration',
          onClick: () => navigate('/consideration', { replace: true }),
        },
        {
          label: 'Decision',
          onClick: () => navigate('/decision', { replace: true }),
        },
      ],
    },
    {
      label: 'Goals',
      items: [
        {
          label: 'Condition',
          onClick: () => navigate('/condition', { replace: true }),
        },
        { label: 'Goal', onClick: () => navigate('/goal', { replace: true }) },
        {
          label: 'Goal Evaluator',
          onClick: () => navigate('/goal-evaluator', { replace: true }),
        },
        {
          label: 'Goal Set',
          onClick: () => navigate('/goal-set', { replace: true }),
        },
      ],
    },
  ]);

  return defaultMenuItems;
};

const DesktopHeader = () => {
  const navigate = useNavigate();
  const menuItems = useDefaultMenuItems();
  // const [menuItems, setMenuItems] = useState<IMenuItem[]>(defaultMenuItems);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<any>();

  return (
    <Box
      direction="row-responsive"
      height="xxsmall"
      justify="between"
      background="neutral-3"
      className="desktop-header"
      align="center"
      fill="horizontal"
      elevation="medium"
    >
      {/* <Box direction="row-responsive"> */}
        <Box direction="row-responsive" justify="start">
          {menuItems &&
            menuItems.map(({ label, items }: IMenuItem, index) => (
              <MenuItem key={label} label={label} items={items} index={index} />
            ))}
        </Box>
      {/* </Box> */}
      <Box direction="row-responsive">
        <MenuItem
          label="Simulate"
          items={[
            {
              label: 'Decisions',
              onClick: () => navigate('/simulate/decisions', { replace: true }),
            },
          ]}
          index={0}
        />
        {loggedIn && !!user && (
          <DropButton
            label={user.spec.username || user.spec.username}
            icon={<Icons.User />}
            color="white"
            dropAlign={{ top: 'bottom', right: 'right' }}
            dropContent={
              <Box>
                <Button
                  label="Logout"
                  color="white"
                  // onClick={() => logout()}
                />
              </Box>
            }
          />
        )}
        {/* {!loggedIn && (
          <Button
            label="Log In"
            color="white"
            onClick={() => navigate('/login', { replace: true })}
          />
        )} */}
      </Box>
    </Box>
  );
};

export default DesktopHeader;
