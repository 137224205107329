/**
 * Entities/GoalSet View
 */
import {
  IGoalSet,
  IScoringFunctionParameters,
  ScoringFunction,
  scoringFunctions,
} from '@gtools/shared';
import {
  Box,
  Text,
  RangeInput,
  Form,
  Main,
  FormField,
  TextInput,
  TextArea,
  Select,
  Card,
  CardBody,
  Button,
  CardHeader,
} from 'grommet';
import * as Icons from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import { SaveGoalSet } from '../../data/goal-sets';
import { ErrorMessage } from '../../components';
import { DataChart } from 'grommet';

const defaultValues: IGoalSet = {
  gameid: '',
  description: '',
  notes: '',
  scoringfunction: ScoringFunction.ExponentialCurve,
  slope: 1,
  exponent: 2,
  midpoint: 0,
  scoringoffset: 0,
  logbase: Math.E,
};

const rangeLabels = [];
for (let i = 0; i <= 100; i += 10) {
  rangeLabels.push(i);
}

const useScoringCurveData = ({
  scoringfunction,
  slope,
  exponent,
  midpoint,
  scoringoffset,
  logbase,
}) => {
  const getScore: (
    input: number,
    params: IScoringFunctionParameters
  ) => number = scoringFunctions[scoringfunction];
  const data = [];

  const maxScore = getScore(100, {
    slope,
    exponent,
    midpoint,
    scoringoffset,
    logbase,
  });

  for (let i = 0; i <= 100; i += 1) {
    const score = getScore(i, {
      slope,
      exponent,
      midpoint,
      scoringoffset,
      logbase,
    });
    data.push({
      input: i,
      score: score / maxScore,
    });
  }

  return data;
};

const CreateGoalSetPage = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState<IGoalSet>(defaultValues);
  const [chartValues, setChartValues] = useState<any[]>([]);
  const [chartLoading, setChartLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!values?.scoringfunction) return;

    setChartLoading(true);

    const {
      scoringfunction,
      slope,
      exponent,
      midpoint,
      scoringoffset,
      logbase,
    } = values;

    const chartValues = useScoringCurveData({
      scoringfunction,
      slope,
      exponent,
      midpoint,
      scoringoffset,
      logbase,
    });

    setChartValues(chartValues);

    setChartLoading(false);
  }, [
    values?.scoringfunction,
    values?.slope,
    values?.exponent,
    values?.midpoint,
    values?.scoringoffset,
    values?.logbase,
  ]);

  return (
    <SaveGoalSet>
      {({ fetching, failed, doFetch }) => {
        if (fetching) return <LoadingSpinner />;
        if (failed)
          return <ErrorMessage error={new Error('Failed to save GoalSet')} />;
        return (
          <Main>
            <Form<IGoalSet>
              value={values}
              onChange={(nextValue: IGoalSet) => {
                setValues(nextValue);
              }}
              onReset={() => setValues(defaultValues)}
              onSubmit={async () => {
                const response = await doFetch({
                  body: JSON.stringify(values),
                });
                navigate('/goal-sets');
              }}
            >
              <Box
                direction="row"
                align="center"
                pad="small"
                background="neutral-3"
                justify="between"
              >
                <Button
                  onClick={() => navigate('/goal-sets')}
                  icon={<Icons.Previous size="medium" />}
                  hoverIndicator
                />
                <Text size="large">New GoalSet</Text>
                <Button
                  icon={<Icons.Checkmark size="medium" />}
                  hoverIndicator
                  focusIndicator={false}
                  type="submit"
                />
              </Box>
              <Box direction="row">
                <Box pad="medium" gap="medium" fill>
                  <Card>
                    <CardHeader
                      background="dark-2"
                      fill="horizontal"
                      pad={{ horizontal: 'small', vertical: 'xsmall' }}
                    >
                      <Text size="small" weight="bold">
                        {'Metadata'.toUpperCase()}
                      </Text>
                    </CardHeader>
                    <CardBody pad="small">
                      <FormField
                        label={'GameID'.toUpperCase()}
                        name="gameid"
                        required
                        validate={{ regexp: /^[a-z]/i }}
                      />
                      <FormField
                        label={'Description'.toUpperCase()}
                        name="description"
                        component={TextArea}
                      />
                      <FormField
                        label={'Notes'.toUpperCase()}
                        name="notes"
                        component={TextArea}
                      />
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader
                      background="dark-2"
                      fill="horizontal"
                      pad={{ horizontal: 'small', vertical: 'xsmall' }}
                    >
                      <Text size="small" weight="bold">
                        {'Scoring Inputs'.toUpperCase()}
                      </Text>
                    </CardHeader>
                    <CardBody pad="small">
                      <FormField
                        label={'Scoring Function'.toUpperCase()}
                        name="scoringfunction"
                        component={Select}
                        options={[
                          ScoringFunction.Linear,
                          ScoringFunction.InverseLinear,
                          ScoringFunction.ExponentialCurve,
                          ScoringFunction.InverseExponentialCurve,
                          ScoringFunction.SineCurve,
                          ScoringFunction.CosineCurve,
                          ScoringFunction.LogisticCurve,
                          ScoringFunction.LogitCurve,
                          ScoringFunction.SmoothStepCurve,
                          ScoringFunction.SmootherStepCurve,
                        ]}
                      />
                      <Box direction="row" align="end">
                        <Box>
                          <FormField
                            label={`Slope (${values.slope})`.toUpperCase()}
                            name="slope"
                            min={0}
                            max={100}
                            step={0.1}
                            component={TextInput}
                          />
                        </Box>
                        <Box fill="horizontal">
                          <FormField
                            name="slope"
                            min={0}
                            max={100}
                            step={0.1}
                            component={RangeInput}
                          />
                        </Box>
                      </Box>
                      <Box direction="row" align="end">
                        <Box>
                          <FormField
                            label={`Exponent (${values.exponent})`.toUpperCase()}
                            name="exponent"
                            min={0}
                            max={100}
                            step={0.1}
                            component={TextInput}
                          />
                        </Box>
                        <Box fill="horizontal">
                          <FormField
                            name="exponent"
                            min={0}
                            max={100}
                            step={0.1}
                            component={RangeInput}
                          />
                        </Box>
                      </Box>
                      <Box direction="row" align="end">
                        <Box>
                          <FormField
                            label={`Midpoint (${values.midpoint})`.toUpperCase()}
                            name="midpoint"
                            min={0}
                            max={100}
                            step={0.1}
                            component={TextInput}
                          />
                        </Box>
                        <Box fill="horizontal">
                          <FormField
                            name="midpoint"
                            min={0}
                            max={100}
                            step={0.1}
                            component={RangeInput}
                          />
                        </Box>
                      </Box>
                      <Box direction="row" align="end">
                        <Box>
                          <FormField
                            label={`Offset (${values.scoringoffset})`.toUpperCase()}
                            name="scoringoffset"
                            min={0}
                            max={100}
                            step={0.1}
                            component={TextInput}
                          />
                        </Box>
                        <Box fill="horizontal">
                          <FormField
                            name="scoringoffset"
                            min={0}
                            max={100}
                            step={0.1}
                            component={RangeInput}
                          />
                        </Box>
                      </Box>
                    </CardBody>
                  </Card>
                </Box>
                <Box pad="medium" gap="medium" fill>
                  <Card>
                    <CardHeader
                      background="dark-2"
                      fill="horizontal"
                      pad={{ horizontal: 'small', vertical: 'xsmall' }}
                    >
                      <Text size="small" weight="bold">
                        {'Scoring Curve'.toUpperCase()}
                      </Text>
                    </CardHeader>
                    <CardBody pad="small" align="center">
                      {chartLoading && <LoadingSpinner />}
                      {chartValues && (
                        <DataChart
                          data={chartValues}
                          series={[
                            {
                              label: 'Input',
                              property: 'input',
                            },
                            {
                              label: 'Score',
                              property: 'score',
                            },
                          ]}
                          chart={[
                            {
                              property: 'score',
                              thickness: 'xsmall',
                              type: 'line',
                              color: 'neutral-3',
                            },
                          ]}
                          guide={{
                            x: { granularity: 'medium' },
                            y: { granularity: 'fine' },
                          }}
                          size={{ width: 'medium', height: 'medium' }}
                          detail
                        ></DataChart>
                      )}
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader
                      background="dark-2"
                      fill="horizontal"
                      pad={{ horizontal: 'small', vertical: 'xsmall' }}
                    >
                      <Text size="small" weight="bold">
                        {'Simulation'.toUpperCase()}
                      </Text>
                    </CardHeader>
                    <CardBody pad="small" align="center">
                      <Box direction="row">
                        <Button icon={<Icons.Play />} />
                        <Button icon={<Icons.Pause />} />
                        <Button icon={<Icons.Stop />} />
                      </Box>
                    </CardBody>
                  </Card>
                </Box>
              </Box>
            </Form>
          </Main>
        );
      }}
    </SaveGoalSet>
  );
};

export default CreateGoalSetPage;
