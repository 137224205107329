import { IActionSet } from '@gtools/shared';
import React, { useEffect, useState } from 'react';
import { Fetch } from 'react-request';
import { getApiBaseUri } from '../lib/fetchUtils';

/**
 * Get ActionSet
 */
export const useActionSet = (actionSetId: number | string) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [actionSet, setActionSet] = useState<IActionSet>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/action-sets/${actionSetId}`
      );
      const data = await response.json();
      setActionSet(data[0]);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!actionSet) fetchData();
  }, [actionSet]);

  return { fetching, failed, complete, actionSet };
};

/**
 * List ActionSets
 */
export const useActionSets = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [actionSets, setActionSets] = useState<IActionSet[]>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/action-sets`
      );
      const data = await response.json();
      setActionSets(data);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!actionSets) fetchData();
  }, [actionSets]);

  return { fetching, failed, complete, actionSets };
};

/**
 * Save ActionSet
 */
export interface SaveActionSetProps {
  children?: any;
}

export function SaveActionSet({ children }: SaveActionSetProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/action-sets`}
      method="POST"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Edit ActionSet
 */
export interface UpdateActionSetProps {
  actionSetId: number | string;
  children: any;
}

export function UpdateActionSet({
  actionSetId,
  children,
}: UpdateActionSetProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/action-sets/${actionSetId}`}
      method="PATCH"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Delete ActionSet
 */
export interface DeleteActionSetProps {
  actionSetId: number | string;
  children: any;
}

export function DeleteActionSet({
  actionSetId,
  children,
}: DeleteActionSetProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/action-sets/${actionSetId}`}
      method="DELETE"
      credentials="same-origin"
      children={children}
    />
  );
}
