import { ICondition } from '@gtools/shared';
import React, { useEffect, useState } from 'react';
import { Fetch } from 'react-request';
import { getApiBaseUri } from '../lib/fetchUtils';

/**
 * Get Condition
 */
export const useCondition = (conditionId: number | string) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [condition, setCondition] = useState<ICondition>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/conditions/${conditionId}`
      );
      const data = await response.json();
      setCondition(data[0]);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!condition) fetchData();
  }, [condition]);

  return { fetching, failed, complete, condition };
};

/**
 * List Conditions
 */
export const useConditions = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [conditions, setConditions] = useState<ICondition[]>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/conditions`
      );
      const data = await response.json();
      setConditions(data);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!conditions) fetchData();
  }, [conditions]);

  return { fetching, failed, complete, conditions };
};

/**
 * Save Condition
 */
export interface SaveConditionProps {
  children?: any;
}

export function SaveCondition({ children }: SaveConditionProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/conditions`}
      method="POST"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Edit Condition
 */
export interface UpdateConditionProps {
  conditionId: number | string;
  children: any;
}

export function UpdateCondition({
  conditionId,
  children,
}: UpdateConditionProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/conditions/${conditionId}`}
      method="PATCH"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Delete Condition
 */
export interface DeleteConditionProps {
  conditionId: number | string;
  children: any;
}

export function DeleteCondition({
  conditionId,
  children,
}: DeleteConditionProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/conditions/${conditionId}`}
      method="DELETE"
      credentials="same-origin"
      children={children}
    />
  );
}
