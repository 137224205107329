import React from 'react';
import { Menu, types } from 'grommet';
import { DropType } from 'grommet';
import { Text } from 'grommet';

export interface MenuItemProps {
  label: string;
  items: any[];
  index?: number;
  dropAlign?: {};
}

const MenuItem = ({
  label,
  items,
  index,
  dropAlign = { top: 'bottom', right: 'right' },
}: MenuItemProps) => (
  <Menu
    key={index}
    label={<Text size="small">{label}</Text>}
    items={items}
    dropAlign={dropAlign}
  />
);

export default MenuItem;
