/**
 * Home View
 */
import { Main } from 'grommet';
import { Menu } from 'grommet';
import {
  Box,
  Grid,
  Heading,
  Image,
  Text,
  Card,
  CardBody,
  Button,
  CardHeader,
} from 'grommet';
import * as Icons from 'grommet-icons';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

const logo = require('../../public/assets/images/lightapp-client-md.png');

const Home = () => {
  const navigate = useNavigate();

  return (
    <Main>
      <Box>
        <Box
          direction="row"
          align="center"
          pad="small"
          background="neutral-3"
          justify="between"
        >
          <Button
            icon={<Icons.Previous size="medium" />}
            disabled
            hoverIndicator
          />
          <Text size="large">gTools</Text>
          <Button disabled icon={<Icons.Next size="medium" />} hoverIndicator />
        </Box>
        <Box>
          <Box pad="medium" gap="medium" direction="row">
            <Card fill>
              <CardHeader
                background="dark-2"
                fill="horizontal"
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text size="small" weight="bold">
                  {'Gameplay'.toUpperCase()}
                </Text>
              </CardHeader>
              <CardBody>
                <Button hoverIndicator>
                  <Box pad="small">
                    <Text>Decision Engine</Text>
                  </Box>
                </Button>
                <Button hoverIndicator>
                  <Box pad="small">
                    <Text>Goal Planner</Text>
                  </Box>
                </Button>
              </CardBody>
            </Card>
            <Card fill>
              <CardHeader
                background="dark-2"
                fill="horizontal"
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text size="small" weight="bold">
                  {'Data'.toUpperCase()}
                </Text>
              </CardHeader>
              <CardBody>
                <Button hoverIndicator onClick={() => navigate('/conditions')}>
                  <Box pad="small">
                    <Text>Conditions</Text>
                  </Box>
                </Button>
                <Button
                  hoverIndicator
                  onClick={() => navigate('/considerations')}
                >
                  <Box pad="small">
                    <Text>Considerations</Text>
                  </Box>
                </Button>
                <Button hoverIndicator onClick={() => navigate('/needs')}>
                  <Box pad="small">
                    <Text>Needs</Text>
                  </Box>
                </Button>
              </CardBody>
            </Card>
          </Box>
          <Box pad="medium" gap="medium" direction="row">
            <Card fill>
              <CardHeader
                background="dark-2"
                fill="horizontal"
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text size="small" weight="bold">
                  {'Graphics'.toUpperCase()}
                </Text>
              </CardHeader>
              <CardBody>
                <Button hoverIndicator>
                  <Box pad="small">
                    <Text>Model Viewer</Text>
                  </Box>
                </Button>
                <Button hoverIndicator>
                  <Box pad="small">
                    <Text>Textures</Text>
                  </Box>
                </Button>
              </CardBody>
            </Card>
            <Card fill>
              <CardHeader
                background="dark-2"
                fill="horizontal"
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text size="small" weight="bold">
                  {'Sound'.toUpperCase()}
                </Text>
              </CardHeader>
              <CardBody>
                <Button hoverIndicator>
                  <Box pad="small">
                    <Text>SFX</Text>
                  </Box>
                </Button>
                <Button hoverIndicator>
                  <Box pad="small">
                    <Text>Music</Text>
                  </Box>
                </Button>
              </CardBody>
            </Card>
          </Box>
        </Box>
      </Box>
    </Main>
  );
};

export default Home;
