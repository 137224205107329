import React, { useEffect, useState } from 'react';
import { Box, Spinner, Text } from 'grommet';
import * as Icons from 'grommet-icons';

export interface TimerProps {
  value: number;
  color?: string;
  background?: string;
  size?: string;
  weight?: number;
}

const Timer: React.FC<TimerProps> = ({
  value,
  color,
  background,
  size = 'large',
  weight = 400,
}) => {
  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);

  const formatTime = (value: number) => `${value < 10 ? '0' : ''}${value}`;

  const displayTime = () =>
    `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`
      .split('')
      .join(' ');

  useEffect(() => {
    setSeconds(value % 60);
    setMinutes(Math.trunc(value / 60));
    setHours(Math.trunc(minutes / 60));
  }, [value]);

  return (
    <Box align="center" justify="center" fill background={background}>
      <Text color={color} size={size} weight={weight}>
        {displayTime()}
      </Text>
    </Box>
  );
};

export default Timer;
