/**
 * GeoffWare Dev Toolset
 */
import debug from 'debug';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './index.css';

const log = debug('geoffware-client');

if (localStorage && process.env.NODE_ENV === 'development') {
  localStorage.debug = process.env.DEBUG;
}

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
