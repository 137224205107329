/**
 * General
 */
export interface IGameEntity {
  id?: number;
  gameid: string;
  description: string;
  notes: string;
}

export enum PriorityType {
  LowPriority = 0.5, // idle actions, etc.
  NormalPriority = 1, // movement
  HighPriority = 2, // reloading, etc.
  Emergency = 3, // low health, etc.
  ExtremeEmergency = 4, // you're seconds from dying forever
}

/**
 * Type Aliases
 */
export type TNumberRange = [number, number];

/**
 * Scoring Functions
 */
export enum ScoringFunction {
  Linear = 'Linear',
  InverseLinear = 'InverseLinear',
  ExponentialCurve = 'ExponentialCurve',
  InverseExponentialCurve = 'InverseExponentialCurve',
  SineCurve = 'SineCurve',
  CosineCurve = 'CosineCurve',
  LogisticCurve = 'LogisticCurve',
  LogitCurve = 'LogitCurve',
  SmoothStepCurve = 'SmoothStepCurve',
  SmootherStepCurve = 'SmootherStepCurve',
}

export interface IScoringFunctionParameters {
  slope?: number;
  exponent?: number;
  midpoint?: number;
  scoringoffset?: number;
  logbase?: number;
}

/**
 * Actions
 */
export interface IAction extends IGameEntity {
  preconditions: ICondition[];
  effects: ICondition[];
}

/**
 * Decisions
 */
export interface IConsideration extends IGameEntity {
  scoringfunction: ScoringFunction;
  slope: number;
  exponent: number;
  midpoint: number;
  scoringoffset: number;
  logbase: number;
}

export interface IDecision extends IGameEntity {
  action: IAction;
}

export interface IActionEvaluator extends IGameEntity {
  decision: IDecision;
  considerations: IConsideration[];
  priority: PriorityType;
}

export interface IActionSet extends IGameEntity {
  action_evaluators: IActionEvaluator[];
}

/**
 * Goals
 */
export interface IGoal extends IGameEntity {
  conditions: ICondition[];
}

export interface IGoalEvaluator extends IGameEntity {
  goal: IGoal;
  considerations: IConsideration[];
  priority: PriorityType;
}

export interface IGoalSet extends IGameEntity {
  goal_evaluators: IGoalEvaluator[];
}

export interface ICondition extends IGameEntity {
  input: number;
  range: TNumberRange;
}

/**
 * Needs
 */
export interface INeed extends IGameEntity {
  decayrate: number;
  decayfunction: ScoringFunction;
  slope: number;
  exponent: number;
  midpoint: number;
  scoringoffset: number;
  logbase: number;
}
