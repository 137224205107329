import { IGoalSet } from '@gtools/shared';
import React, { useEffect, useState } from 'react';
import { Fetch } from 'react-request';
import { getApiBaseUri } from '../lib/fetchUtils';

/**
 * Get GoalSet
 */
export const useGoalSet = (goalSetId: number | string) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [goalSet, setGoalSet] = useState<IGoalSet>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/goal-sets/${goalSetId}`
      );
      const data = await response.json();
      setGoalSet(data[0]);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!goalSet) fetchData();
  }, [goalSet]);

  return { fetching, failed, complete, goalSet };
};

/**
 * List GoalSets
 */
export const useGoalSets = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [goalSets, setGoalSets] = useState<IGoalSet[]>();

  const fetchData = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `${getApiBaseUri()}/goal-sets`
      );
      const data = await response.json();
      setGoalSets(data);
    } catch (err) {
      setFailed(true);
    }
    setFetching(false);
    setComplete(true);
  };

  useEffect(() => {
    if (!goalSets) fetchData();
  }, [goalSets]);

  return { fetching, failed, complete, goalSets };
};

/**
 * Save GoalSet
 */
export interface SaveGoalSetProps {
  children?: any;
}

export function SaveGoalSet({ children }: SaveGoalSetProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/goal-sets`}
      method="POST"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Edit GoalSet
 */
export interface UpdateGoalSetProps {
  goalSetId: number | string;
  children: any;
}

export function UpdateGoalSet({ goalSetId, children }: UpdateGoalSetProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/goal-sets/${goalSetId}`}
      method="PATCH"
      headers={{ 'Content-Type': 'application/json' }}
      credentials="same-origin"
      children={children}
    />
  );
}

/**
 * Delete GoalSet
 */
export interface DeleteGoalSetProps {
  goalSetId: number | string;
  children: any;
}

export function DeleteGoalSet({ goalSetId, children }: DeleteGoalSetProps) {
  return (
    <Fetch
      url={`${getApiBaseUri()}/goal-sets/${goalSetId}`}
      method="DELETE"
      credentials="same-origin"
      children={children}
    />
  );
}
